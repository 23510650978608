<template>
  <div class="page-main">
    <!--新增账号-->
    <el-dialog
      width="500px"
      :title="showEdit ? '编辑账号' : '新增账号'"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="refForm" :rules="accountFormRules" :model="accountForm" label-width="100px">
        <el-form-item label="姓名:" prop="nick_name">
          <el-input size="mini" v-model="accountForm.nick_name" />
        </el-form-item>
        <el-form-item label="手机:" prop="user_name">
          <el-input size="mini" v-model="accountForm.user_name" />
        </el-form-item>
        <el-form-item label="企业名称:" prop="company_name">
          <el-input size="mini" v-model="accountForm.company_name" />
        </el-form-item>
        <el-form-item label="机器人授权供应商:" prop="aiCompanyList" label-width="130px">
          <el-select
              style="width: 100%"
              v-model="accountForm.aiCompanyList"
              collapse-tags
              size="mini"
              multiple
              filterable
              clearable
              default-first-option
              placeholder="可多选">
            <el-option
                v-for="item in aiCompanyList"
                :key="item.company_id"
                :label="item.company_id+'-'+item.company_name"
                :value="item.company_name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职务:" prop="job">
          <el-input size="mini" v-model="accountForm.job" />
        </el-form-item>
        <el-form-item label="提成比例:" prop="commission_rate">
          <el-input size="mini" type="number" v-model="accountForm.commission_rate">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="密码:" prop="pass_word">
          <el-input
            v-model="accountForm.pass_word"
            size="mini"
            show-password
            auto-complete="new-password"
            :placeholder="showEdit ? '留空不修改密码，必须包含字母+数字，6~15位' : ''"
          />
        </el-form-item>
        <el-form-item v-if="$tool.hasControl(['/account/save'])" label="角色:" prop="roles">
          <el-select
            v-model="accountForm.roles"
            size="mini"
            multiple
            placeholder="可搜索查询"
            style="width: 100%"
          >
            <el-option
              v-for="item in roleList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="saveAccount('refForm', 'save')">
          确定
        </el-button>
      </div>
    </el-dialog>

    <!--批量新增账号-->
    <el-dialog
      width="420px"
      :title="dialogBatchUpdate ? '批量修改角色' : '批量新增账号'"
      :visible.sync="dialogBatch"
      :close-on-click-modal="false"
    >
      <el-form ref="refBatchForm" :model="accountForm" :rules="accountFormRules">
        <el-tooltip placement="top-start">
          <div slot="content">
            <p>多个名字之间请用(空格)或(，)或(,)或(；)或(;)进行分隔</p>
            <p>例如：小明 小红</p>
          </div>
          <el-form-item label="姓名:" label-width="68px" prop="nickNameList">
            <el-input
              type="textarea"
              size="mini"
              :rows="2"
              placeholder="请输入内容"
              :disabled="this.dialogBatchUpdate"
              v-model="accountForm.nickNameList"
            >
            </el-input>
          </el-form-item>
        </el-tooltip>
        <template v-if="this.dialogBatchUpdate">
          <el-radio
            border
            size="mini"
            style="margin-left: 70px; margin-top: 10px"
            v-model="accountForm.isRolesTypeSave"
            :label="true"
            >新增角色
          </el-radio>
          <el-radio border size="mini" v-model="accountForm.isRolesTypeSave" :label="false">
            删除角色
          </el-radio>
        </template>
        <el-form-item label="角色:" label-width="68px" prop="roles">
          <el-select
            size="mini"
            v-model="accountForm.roles"
            multiple
            placeholder="可搜索查询"
            style="width: 100%"
          >
            <el-option
              v-for="item in roleList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="启用:" label-width="68px">
          <el-switch v-model="accountForm.state" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogBatch = false">取消</el-button>
        <el-button
          type="primary"
          size="mini"
          @click="saveAccount('refBatchForm', 'batchSaveOrUpdate')"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Account',
  props: {},
  data() {
    return {
      isTable: false,
      dialogFormVisible: false,
      dialogBatch: false,
      dialogBatchUpdate: false,
      showEdit: false,
      accountForm: {
        nick_name: '',
        job: '',
        company_name: '',
        isBatchSaveType: true,
        nickNameList: '',
        user_name: '',
        pass_word: '',
        role_name: '',
        shortcut_key: '',
        commission_rate: '',
        state: true,
        roles: [],
        aiCompanyList: [],
        isRolesTypeSave: true,
        billing_company_id: '',
        billing_company_name: ''
      },
      roleList: [],
      aiCompanyList: [],
      accountFormRules: {
        nick_name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        nickNameList: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        user_name: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        pass_word: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          },
          {
            min: 6,
            max: 15,
            message: '密码长度在 6 到 15 个字符',
            trigger: 'blur'
          }
        ],
        roles: [{ required: false, message: '请输入查询角色权限', trigger: 'blur' }]
      }
    }
  },

  created() {
    this.getRoleList()
    this.getAiCompanyList()
    this.isTable = false
  },
  methods: {
    // 每次刷新，重新读取用户权限
    findRoleVersion() {
      this.$api({
        method: 'post',
        url: '/admin/roleVersion',
        data: {
          roleVersion: this.$store.getters.getRoleVersion
        }
      }).then((res) => {
        if (res.status === 200) {
          if (res.data.roleVersion != null && res.data.account != null) {
            this.$store.dispatch('asyncUpdateRoleVersion', res.data.roleVersion)
            this.$store.dispatch('asyncUpdateLoginAccount', res.data.account)
          }
        }
      })
    },
    doBatchUpdteEvent() {
      // 获取所勾选的值，如果没有勾选则提示先勾选
      this.accountForm.isBatchSaveType = false
      let selectRecords = this.$parent.$refs.refTable.getCheckboxRecords() // vue子组件调用父组件ref
      this.accountForm.nickNameList = ''
      selectRecords.forEach((item) => {
        this.accountForm.nickNameList = item.nick_name + ',' + this.accountForm.nickNameList
      })
      if (selectRecords == null || selectRecords.length == 0) {
        this.$XModal.alert('请先选择需要修改的账户！')
      } else {
        this.dialogBatchUpdate = true
        this.dialogBatch = true
      }
    },
    getRoleList() {
      // 获取RoleList
      this.$api({
        method: 'post',
        url: '/admin/account/assignRolesListVue'
      })
        .then((res) => {
          if (res.status === 200) {
            this.roleList = res.data.roleList
          }
        })
        .catch(() => {
          return
        })
    },
    getAiCompanyList() {
      // 获取RoleList
      this.$api({
        method: 'post',
        url: '/admin/aiCompany'
      })
          .then((res) => {
            if (res.status === 200) {
              this.aiCompanyList = res.data.list
            }
          })
          .catch(() => {
            return
          })
    },
    addAccount() {
      this.accountFormRules.pass_word[0].required = true
      this.dialogFormVisible = true
      this.showEdit = false
      this.accountForm.pass_word = ''
      this.accountForm.job = ''
      this.accountForm.nick_name = ''
      this.accountForm.company_name = ''
      this.accountForm.nickNameList = ''
      this.accountForm.user_name = ''
      this.accountForm.id = ''
      this.accountForm.billing_company_id = ''
      this.accountForm.billing_company_name = ''
      this.accountForm.commission_rate = ''
      this.accountForm.roles = []
    },
    editRowEvent(accountId) {
      this.showEdit = true
      this.dialogFormVisible = true
      this.accountForm.pass_word = ''
      this.$api({
        method: 'post',
        url: '/admin/account/editVue',
        params: {
          id: accountId
        }
      }).then((res) => {
        if (res.status === 200) {
          this.accountForm.nick_name = res.data.account.nick_name
          this.accountForm.user_name = res.data.account.user_name
          this.accountForm.id = res.data.account.id
          this.accountForm.aiCompanyList = res.data.account.ai_company_list
          this.accountForm.job = res.data.account.job
          this.accountForm.company_name = res.data.account.company_name
          this.accountForm.shortcut_key = res.data.account.shortcut_key
          this.accountForm.billing_company_id = res.data.account.billing_company_id
          this.accountForm.billing_company_name = res.data.account.billing_company_name
          this.accountForm.commission_rate = res.data.account.commission_rate
          this.accountFormRules.pass_word[0].required = false
          let rList = []
          for (let r in res.data.account.role_name) {
            rList.push(res.data.account.role_name[r].id)
          }
          this.accountForm.roles = rList
        }
      })
    },

    saveAccount(formName, t) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let url = ''
          // 判断是否超级管理员
          if (this.$tool.hasControl(['/account/save'])) {
            if (t == 'save' && this.showEdit) {
              url = '/admin/account/updateVue'
            } else if (t == 'save' && !this.showEdit) {
              url = '/admin/account/saveVue'
            } else if (t == 'batchSaveOrUpdate') {
              url = '/admin/account/batchSaveOrUpdate'
            }
          } else {
            url = '/admin/account/myPassWord'
          }
          this.$api({
            method: 'post',
            url: url,
            data: this.accountForm
          })
            .then((res) => {
              if (res.status === 200 && res.data.state === 'ok') {
                this.$message.success(res.data.msg)
                this.dialogFormVisible = false
                this.dialogBatch = false
                this.findRoleVersion()
                if (this.isTable) {
                  this.$parent.getListData()
                }
              } else {
                this.$message.error(res.data.msg)
              }
            })
            .catch((error) => {
              this.$message.error(error)
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
