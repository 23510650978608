const routes = [
  // 登录
  {
    path: '/login',
    meta: { title: '登录' },
    component: () => import('@/views/login')
  },
  // 首页
  {
    path: '/',
    redirect: { path: '/home' }
  },
  {
    path: '/home',
    meta: { title: '首页' },
    component: () => import('@/views/home')
  },
  // 销售管理
  {
    path: '/sale',
    meta: { title: '销售管理' },
    component: () => import('@/views/sale'),
    children: [
      {
        path: '/sale/order',
        meta: { title: '核算单' },
        component: () => import('@/views/sale/order'),
        redirect: { path: '/sale/order/list' },
        children: [
          {
            path: '/sale/order/list',
            meta: { title: '核算单列表' },
            component: () => import('@/views/sale/order/list')
          },
          {
            path: '/sale/order/detail',
            meta: { title: '核算单详情' },
            component: () => import('@/views/sale/order/detail')
          },
          {
            path: '/sale/order/old-detail',
            meta: { title: '核算单详情-旧版' },
            component: () => import('@/views/sale/order/old-detail')
          },
          {
            path: '/sale/order/print',
            meta: { title: '核算单打印' },
            component: () => import('@/views/sale/order/print')
          }
        ]
      },
      {
        path: '/sale/dingdan',
        meta: { title: '订单' },
        component: () => import('@/views/sale/dingdan'),
        redirect: { path: '/sale/dingdan/list' },
        children: [
          {
            path: '/sale/dingdan/list',
            meta: { title: '订单列表' },
            component: () => import('@/views/sale/dingdan/list')
          }
        ]
      },
      {
        path: '/sale/customer',
        meta: { title: '送货单' },
        component: () => import('@/views/sale/delivery'),
        redirect: { path: '/sale/delivery/list' },
        children: [
          {
            path: '/sale/delivery/list',
            meta: { title: '送货单列表' },
            component: () => import('@/views/sale/delivery/list')
          },
          {
            path: '/sale/delivery/print',
            meta: { title: '送货单打印' },
            component: () => import('@/views/sale/delivery/print')
          }
        ]
      },
      {
        path: '/sale/customer',
        meta: { title: '客户' },
        component: () => import('@/views/sale/customer'),
        redirect: { path: '/sale/customer/list' },
        children: [
          {
            path: '/sale/customer/list',
            meta: { title: '客户列表' },
            component: () => import('@/views/sale/customer/list')
          }
        ]
      },
      {
        path: '/sale/quote',
        meta: { title: '报价单' },
        component: () => import('@/views/sale/quote'),
        redirect: { path: '/sale/quote/list' },
        children: [
          {
            path: '/sale/quote/list',
            meta: { title: '报价单列表' },
            component: () => import('@/views/sale/quote/list')
          },
          {
            path: '/sale/quote/detail',
            meta: { title: '报价单详情' },
            component: () => import('@/views/sale/quote/detail')
          },
          {
            path: '/sale/quote/print',
            meta: { title: '报价单打印' },
            component: () => import('@/views/sale/quote/print')
          }
        ]
      },
      {
        path: '/sale/contract',
        meta: { title: '销售合同' },
        component: () => import('@/views/sale/contract'),
        redirect: { path: '/sale/contract/list' },
        children: [
          {
            path: '/sale/contract/list',
            meta: { title: '销售合同列表' },
            component: () => import('@/views/sale/contract/list')
          },
          {
            path: '/sale/contract/detail',
            meta: { title: '销售合同详情' },
            component: () => import('@/views/sale/contract/detail')
          },
          {
            path: '/sale/contract/print',
            meta: { title: '销售合同打印' },
            component: () => import('@/views/sale/contract/print')
          }
        ]
      }
    ]
  },
  // 财务管理
  {
    path: '/finance',
    meta: { title: '财务管理' },
    component: () => import('@/views/finance'),
    children: [
      {
        path: '/finance/payin',
        meta: { title: '财务' },
        component: () => import('@/views/finance/payin'),
        redirect: { path: '/finance/payin/list' },
        children: [
          {
            path: '/finance/payin/list',
            meta: { title: '应收款列表' },
            component: () => import('@/views/finance/payin/list')
          },
          {
            path: '/finance/payin/detail',
            meta: { title: '财务详情' },
            component: () => import('@/views/finance/payin/detail')
          }
        ]
      }
    ]
  },
  // 采购管理
  {
    path: '/purchase',
    meta: { title: '采购管理' },
    component: () => import('@/views/purchase'),
    children: [
      {
        path: '/purchase/order',
        meta: { title: '采购订单' },
        component: () => import('@/views/purchase/order'),
        redirect: { path: '/purchase/order/list' },
        children: [
          {
            path: '/purchase/order/list',
            meta: { title: '采购列表' },
            component: () => import('@/views/purchase/order/list')
          },
          {
            path: '/purchase/order/detail-one',
            meta: { title: '采购单详情' },
            component: () => import('@/views/purchase/order/detail-one')
          },
          {
            path: '/purchase/order/detail-all',
            meta: { title: '采购单详情' },
            component: () => import('@/views/purchase/order/detail-all')
          },
          {
            path: '/purchase/order/print-xunjia',
            meta: { title: '询价单打印' },
            component: () => import('@/views/purchase/order/print-xunjia')
          },
          {
            path: '/purchase/order/print-caigou',
            meta: { title: '采购单打印' },
            component: () => import('@/views/purchase/order/print-caigou')
          }
        ]
      }
    ]
  },
  // 库存管理
  {
    path: '/stock',
    meta: { title: '库存管理' },
    component: () => import('@/views/stock'),
    children: [
      {
        path: '/stock/stock',
        meta: { title: '库存' },
        component: () => import('@/views/stock/stock'),
        redirect: { path: '/stock/stock/list' },
        children: [
          {
            path: '/stock/stock/head',
            meta: { title: '商品表头属性管理' },
            component: () => import('@/views/stock/stock/head')
          },
          {
            path: '/stock/stock/price',
            meta: { title: '库存商品单价管理' },
            component: () => import('@/views/stock/stock/price')
          },
          {
            path: '/stock/stock/list',
            meta: { title: '库存列表' },
            component: () => import('@/views/stock/stock/list')
          },
          {
            path: '/stock/stock/old-list',
            meta: { title: '库存列表-旧版' },
            component: () => import('@/views/stock/stock/old-list')
          }
        ]
      }
    ]
  },
  // 商品管理
  {
    path: '/goods',
    meta: { title: '商品管理' },
    component: () => import('@/views/goods'),
    children: [
      {
        path: '/goods/category',
        meta: { title: '商品类目' },
        component: () => import('@/views/goods/category'),
        redirect: { path: '/goods/category/list' },
        children: [
          {
            path: '/goods/category/manage',
            meta: { title: '商品类目管理' },
            component: () => import('@/views/goods/category/manage')
          },
          {
            path: '/goods/category/set',
            meta: { title: '商品类目维护' },
            component: () => import('@/views/goods/category/set')
          },
          {
            path: '/goods/category/list',
            meta: { title: '商品类目列表' },
            component: () => import('@/views/goods/category/list')
          }
        ]
      }
    ]
  },
  // 基础设置
  {
    path: '/base',
    meta: { title: '基础设置' },
    component: () => import('@/views/base'),
    children: [
      {
        path: '/base/supplier',
        meta: { title: '供应商' },
        component: () => import('@/views/base/supplier'),
        redirect: { path: '/base/supplier/list' },
        children: [
          {
            path: '/base/supplier/list',
            meta: { title: '供应商列表' },
            component: () => import('@/views/base/supplier/list')
          }
        ]
      },
      {
        path: '/base/processor',
        meta: { title: '加工商' },
        component: () => import('@/views/base/processor'),
        redirect: { path: '/base/processor/list' },
        children: [
          {
            path: '/base/processor/list',
            meta: { title: '加工商列表' },
            component: () => import('@/views/base/processor/list')
          }
        ]
      },
      {
        path: '/base/warehouse',
        meta: { title: '仓库' },
        component: () => import('@/views/base/warehouse'),
        redirect: { path: '/base/warehouse/list' },
        children: [
          {
            path: '/base/warehouse/list',
            meta: { title: '仓库列表' },
            component: () => import('@/views/base/warehouse/list')
          }
        ]
      },
      {
        path: '/base/category',
        meta: { title: '类别' },
        component: () => import('@/views/base/category'),
        redirect: { path: '/base/category/list' },
        children: [
          {
            path: '/base/category/list',
            meta: { title: '类别列表' },
            component: () => import('@/views/base/category/list')
          }
        ]
      },
      {
        path: '/base/dict',
        meta: { title: '参数' },
        component: () => import('@/views/base/dict'),
        redirect: { path: '/base/dict/list' },
        children: [
          {
            path: '/base/dict/list',
            meta: { title: '参数列表' },
            component: () => import('@/views/base/dict/list')
          },
          {
            path: '/base/dict/detail',
            meta: { title: '参数详情' },
            component: () => import('@/views/base/dict/detail')
          }
        ]
      },
      {
        path: '/base/unit',
        meta: { title: '单位' },
        component: () => import('@/views/base/unit'),
        redirect: { path: '/base/unit/list' },
        children: [
          {
            path: '/base/unit/list',
            meta: { title: '单位列表' },
            component: () => import('@/views/base/unit/list')
          }
        ]
      },
      {
        path: '/base/address',
        meta: { title: '地址' },
        component: () => import('@/views/base/address'),
        redirect: { path: '/base/address/list' },
        children: [
          {
            path: '/base/address/list',
            meta: { title: '地址列表' },
            component: () => import('@/views/base/address/list')
          }
        ]
      },
      {
        path: '/base/seal',
        meta: { title: '印章' },
        component: () => import('@/views/base/seal'),
        redirect: { path: '/base/seal/list' },
        children: [
          {
            path: '/base/seal/list',
            meta: { title: '印章列表' },
            component: () => import('@/views/base/seal/list')
          }
        ]
      }
    ]
  },
  // 系统设置
  {
    path: '/system',
    meta: { title: '系统设置' },
    component: () => import('@/views/system'),
    children: [
      {
        path: '/system/account',
        meta: { title: '账户' },
        component: () => import('@/views/system/account'),
        redirect: { path: '/system/account/list' },
        children: [
          {
            path: '/system/account/list',
            meta: { title: '账户列表' },
            component: () => import('@/views/system/account/list')
          }
        ]
      },
      {
        path: '/system/role',
        meta: { title: '角色' },
        component: () => import('@/views/system/role'),
        redirect: { path: '/system/role/list' },
        children: [
          {
            path: '/system/role/list',
            meta: { title: '角色列表' },
            component: () => import('@/views/system/role/list')
          },
          {
            path: '/system/role/detail',
            meta: { title: '角色详情' },
            component: () => import('@/views/system/role/detail')
          }
        ]
      },
      {
        path: '/system/logger',
        meta: { title: '操作日志' },
        component: () => import('@/views/system/logger'),
        redirect: { path: '/system/logger/list' },
        children: [
          {
            path: '/system/logger/list',
            meta: { title: '操作日志列表' },
            component: () => import('@/views/system/logger/list')
          }
        ]
      }
    ]
  },
  // 考勤管理
  {
    path: '/attendance',
    meta: { title: '考勤管理' },
    component: () => import('@/views/attendance'),
    children: [
      {
        path: '/attendance/attendance',
        meta: { title: '考勤' },
        component: () => import('@/views/attendance/attendance'),
        redirect: { path: '/attendance/attendance/list' },
        children: [
          {
            path: '/attendance/attendance/list',
            meta: { title: '考勤列表' },
            component: () => import('@/views/attendance/attendance/list')
          }
        ]
      },
      {
        path: '/attendance/adjustment',
        meta: { title: '调休' },
        component: () => import('@/views/attendance/adjustment'),
        redirect: { path: '/attendance/adjustment/list' },
        children: [
          {
            path: '/attendance/adjustment/list',
            meta: { title: '调休列表' },
            component: () => import('@/views/attendance/adjustment/list')
          }
        ]
      }
    ]
  },
  // 找不到页面
  {
    path: '/empty',
    meta: { title: '找不到页面' },
    component: () => import('@/views/empty')
  },
  {
    path: '/*',
    redirect: { path: '/empty' }
  }
]

export default routes
