import { render, staticRenderFns } from "./b-popup-customer.vue?vue&type=template&id=70a23eb7"
import script from "./b-popup-customer.vue?vue&type=script&lang=js"
export * from "./b-popup-customer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports