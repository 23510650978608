<template>
  <div class="box-upload">
    <el-upload
      :limit="limit"
      action="#"
      list-type="picture-card"
      accept=".jpg, .jpeg, .png"
      :multiple="limit > 1"
      :file-list="selfList.slice(0, limit)"
      :http-request="fileUpload"
      :on-remove="fileDelete"
      :on-exceed="fileExceed"
    >
      <i v-if="!selfDisabled" slot="default" class="el-icon-plus"></i>
      <template #file="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="file.url" />
        <div class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview">
            <i class="el-icon-zoom-in">
              <el-image
                :src="file.url"
                :preview-src-list="imgUrlList"
                @click.stop.prevent="bigImageAddElement"
              />
            </i>
          </span>
          <span v-if="isDownload" class="el-upload-list__item-delete" @click="fileDownload(file)">
            <i class="el-icon-download"></i>
          </span>
          <span
            v-if="selfDelete && Number(size.split('px')[0]) >= 60"
            class="el-upload-list__item-delete"
            @click="fileDelete(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </div>
      </template>
    </el-upload>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true
    },
    size: {
      type: String,
      default: '120px'
    },
    limit: {
      type: Number,
      default: 5
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isDownload: {
      type: Boolean,
      default: false
    },
    isDelete: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selfList: []
    }
  },
  computed: {
    selfDisabled() {
      return this.selfList.length >= this.limit || this.disabled
    },
    selfDelete() {
      return this.isDelete && !this.disabled
    },
    uploadDisplay() {
      return this.selfDisabled ? 'none' : 'inline-block'
    },
    lastImgMarginSize() {
      return this.selfDisabled ? '0' : '5px'
    },
    iconSize() {
      let result = '20px'
      let sizeArr = this.size.split('px')
      if (sizeArr.length) {
        let size = Number(sizeArr[0])
        if (size > 80 && size <= 100) {
          result = '18px'
        }
        if (size > 60 && size <= 80) {
          result = '16px'
        }
        if (size > 40 && size <= 60) {
          result = '14px'
        }
        if (size <= 40) {
          result = '12px'
        }
      }
      return result
    },
    iconMarginLeft() {
      let result = '10px'
      let sizeArr = this.size.split('px')
      if (sizeArr.length) {
        let size = Number(sizeArr[0])
        if (size > 80 && size <= 100) {
          result = '8px'
        }
        if (size > 60 && size <= 80) {
          result = '6px'
        }
        if (size > 40 && size <= 60) {
          result = '4px'
        }
        if (size <= 40) {
          result = '2px'
        }
      }
      return result
    },
    imgUrlList() {
      return this.selfList.map((item) => item.url)
    }
  },
  watch: {
    list: {
      handler(val) {
        if (JSON.stringify(this.selfList) !== JSON.stringify(val)) {
          this.selfList = this.$util.deepCopy(val)
        }
      },
      deep: true,
      immediate: true
    },
    selfList: {
      handler(val) {
        if (JSON.stringify(this.list) !== JSON.stringify(val)) {
          this.$emit('update:list', val)
        }
      },
      deep: true
    }
  },
  methods: {
    // 文件上传
    fileUpload(option) {
      const params = new FormData()
      const file = option.file
      params.append('file', file)
      params.append('uid', file.uid)
      this.$api({
        method: 'post',
        url: '/admin/upload/imgCommon',
        data: params
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.selfList.push(res.data.img)
            this.$message.success('上传图片成功!')
          } else {
            this.$message.error(res.data.msg)
            this.fileDelete(file) // 上传失败，直接移除
          }
        })
        .catch(() => {
          this.fileDelete(file) // 上传失败，直接移除
        })
    },
    // 文件超出限制
    fileExceed() {
      this.$message.error(`最多上传${this.limit}张图片！`)
    },
    // 文件删除
    fileDelete(file) {
      let index = this.selfList.findIndex((item) => item.id === file.id)
      this.selfList.splice(index, 1)
      this.$api({
        method: 'post',
        url: '/admin/upload/deleteImg',
        data: {
          url: file.url,
          uid: file.uid
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          // this.$message.success('图片删除成功！')
        } else {
          this.$message.error('图片删除失败！')
        }
      })
    },
    // 文件下载
    fileDownload(file) {
      window.open(file.url, '_blank')
    },
    // 放大图片组件-新增下载、删除元素
    bigImageAddElement() {
      this.$nextTick(() => {
        let wrapper = document.getElementsByClassName('el-image-viewer__actions__inner')

        // 新增图片下载按钮
        let downloadBtn = document.createElement('i')
        downloadBtn.setAttribute('class', 'el-icon-download')
        downloadBtn.addEventListener('click', this.bigImageDownload)
        wrapper[0].appendChild(downloadBtn)

        // 新增图片删除按钮
        if (this.selfDelete) {
          let deleteBtn = document.createElement('i')
          deleteBtn.setAttribute('class', 'el-icon-delete')
          deleteBtn.addEventListener('click', this.bigImageDelete)
          wrapper[0].appendChild(deleteBtn)
        }

        if (wrapper.length > 0) {
          this.wrapperElem = wrapper[0]
        }
      })
    },
    // 放大图片组件-图片下载
    bigImageDownload() {
      const imgUrl = document.getElementsByClassName('el-image-viewer__img')[0].src
      this.fileDownload({ url: imgUrl })
    },
    // 放大图片组件-图片删除
    bigImageDelete() {
      const imgUrl = document.getElementsByClassName('el-image-viewer__img')[0].src
      let index = this.selfList.findIndex((item) => item.url === imgUrl)
      this.selfList.splice(index, 1)
    }
  }
}
</script>

<style lang="less" scoped>
.box-upload {
  /deep/ .el-upload,
  /deep/ .el-upload-list__item {
    width: v-bind(size);
    height: v-bind(size);
    line-height: 1;
    transition: none; // 去除默认fade动画
    margin-bottom: 0;
  }

  // 达到限制，不显示上传
  /deep/ .el-upload {
    display: v-bind(uploadDisplay);
    line-height: v-bind(size);
  }

  /deep/ .el-upload-list__item {
    margin-right: 5px;
    &:last-child {
      margin-right: v-bind(lastImgMarginSize);
    }
    &.is-ready {
      display: none;
    }
  }

  /deep/ .el-upload-list--picture-card {
    line-height: 0;
  }

  /deep/ .el-upload-list__item-status-label {
    display: none;
  }

  /deep/ .el-upload--picture-card i {
    font-size: v-bind(iconSize);
    line-height: inherit;
  }

  /deep/ .el-upload-list--picture-card .el-upload-list__item-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: v-bind(iconSize);
    span + span {
      margin-left: v-bind(iconMarginLeft);
    }
  }

  /deep/ .el-icon-zoom-in {
    position: relative;
    .el-image {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }
}
</style>
