import Vue from 'vue'
import Vuex from 'vuex'
import persistedState from 'vuex-persistedstate'

Vue.use(Vuex)
export default new Vuex.Store({
  // Vuex持久化插件(vuex-persistedstate)解决刷新数据消失的问题
  plugins: [persistedState()],
  // 数据，相当于data
  state: {
    roleVersion: 0, // 权限版本
    loginAccount: { actionKeyList: [] },
    navList: [], // 导航列表
    runTimeTest: false // 是否是测试环境
  },
  // 计算属性，相当于computed
  getters: {
    getRoleVersion(state) {
      return state.roleVersion
    },
    getLoginAccount(state) {
      return state.loginAccount
    },
    getNavList(state) {
      return state.navList
    },
    getRoleObj(state) {
      let result = {
        isTourist: false,
        isAdmin: false,
        isSaleLeader: false,
        isSale: false,
        isIt: false
      }
      let roleList = state.loginAccount.role_name
      result.isTourist = !roleList.length || (roleList.length === 1 && roleList[0].id === 26)
      if (roleList.length) {
        roleList.forEach((item) => {
          if (item.id === 1) result.isAdmin = true
          if (item.id === 32) result.isSaleLeader = true
          if (item.id === 10) result.isSale = true
          if (item.id === 41) result.isIt = true
        })
      }
      return result
    },
    getRunTimeTest(state) {
      return state.runTimeTest
    }
  },
  /**
   * 方法，相当于methods
   * state中的变量只能在mutations中通过方法修改
   * this.$store.commit(key, val)
   */
  mutations: {
    updateRoleVersion(state, roleVersion) {
      state.roleVersion = roleVersion
    },
    updateLoginAccount(state, loginAccount) {
      state.loginAccount = loginAccount
    },
    updateNavList(state, navList) {
      state.navList = navList
    },
    updateRunTimeTest(state, runTimeTest) {
      state.runTimeTest = runTimeTest
    }
  },
  /**
   * actions异步方法，相当于async methods
   * state中的变量只能在mutations中通过方法修改
   * this.$store.dispatch(key, val)
   */
  actions: {
    asyncUpdateRoleVersion(context, roleVersion) {
      context.commit('updateRoleVersion', roleVersion)
    },
    asyncUpdateLoginAccount(context, loginAccount) {
      context.commit('updateLoginAccount', loginAccount)
    },
    asyncUpdateRunTimeTest(context, runTimeTest) {
      context.commit('updateRunTimeTest', runTimeTest)
    }
  }
})
