import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store'

// 修复重复跳转相同的路由，控制台报错问题
const vueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return vueRouterPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  // 一级菜单，跳转到子集第一个菜单
  let navList = store.getters.getNavList
  navList.forEach((item) => {
    if (item.path === to.path) {
      if (item.children && item.children.length) {
        router.push(item.children[0].path)
      }
    }
  })

  document.title = `钢材伙伴-${to.meta.title}`
  const sessionId = localStorage.getItem('sessionId')
  if (to.path === '/login') {
    if (sessionId) {
      return next('/')
    } else {
      return next()
    }
  }
  if (!sessionId) {
    return next('/login')
  } else {
    next()
  }
})

export default router
