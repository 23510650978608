<template>
  <el-date-picker
    v-model="datePicker"
    class="e-date-picker"
    :style="{ width }"
    size="mini"
    :type="type"
    unlink-panels
    range-separator="至"
    :placeholder="placeholder"
    :start-placeholder="startPlaceholder"
    :end-placeholder="endPlaceholder"
    :format="format"
    :value-format="valueFormat"
    :picker-options="pickerOptions"
    :disabled="disabled"
    :clearable="clearable"
    @change="change"
    @blur="blur"
  />
</template>

<script>
let daySeconds = 1000 * 60 * 60 * 24 // 一天秒数 86400000
export default {
  props: {
    type: {
      type: String,
      default: 'daterange'
    },
    value: {
      type: [String, Array],
      default: () => {
        return []
      }
    },
    format: {
      type: String,
      default: 'yyyy-MM-dd' // 显示格式
    },
    valueFormat: {
      type: String,
      default: 'yyyy-MM-dd' // 提交格式
    },
    width: {
      type: String,
      default: '170px'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    pickerOptions() {
      let result = {}

      // 快捷选项
      let shortcuts = [
        {
          text: '昨天',
          onClick: (picker) => {
            let now = new Date()
            let start = new Date(now.getTime() - daySeconds * 1)
            let end = new Date(now.getTime() - daySeconds * 1)

            let result = [start, end]
            picker.$emit('pick', result)
          }
        },
        {
          text: '今天',
          onClick: (picker) => {
            let now = new Date()
            let start = new Date()

            let result = [start, now]
            picker.$emit('pick', result)
          }
        },
        {
          text: '本周',
          onClick: (picker) => {
            let now = new Date()
            let week = now.getDay() // 周 一~日 1~7
            let start = new Date(now.getTime() + daySeconds * (1 - week))
            let end = new Date(now.getTime() + daySeconds * (7 - week))

            let result = [start, end]
            picker.$emit('pick', result)
          }
        },
        {
          text: '上周',
          onClick: (picker) => {
            let now = new Date()
            let week = now.getDay() // 周 一~日 1~7
            let start = new Date(now.getTime() + daySeconds * (1 - week - 7))
            let end = new Date(now.getTime() + daySeconds * (7 - week - 7))

            let result = [start, end]
            picker.$emit('pick', result)
          }
        },
        {
          text: '本月',
          onClick: (picker) => {
            let start = new Date()
            start.setDate(1)

            let end = new Date()
            end.setMonth(end.getMonth() + 1) // 先设置为下一个月
            end.setDate(0) // 再设置本月第0天，即上一个月的最后一天

            let result = [start, end]
            picker.$emit('pick', result)
          }
        },
        {
          text: '上月',
          onClick: (picker) => {
            let start = new Date()
            start.setMonth(start.getMonth() - 1) // 先设置为上一个月
            start.setDate(1)

            let end = new Date()
            end.setDate(0) // 设置本月第0天，即上一个月的最后一天

            let result = [start, end]
            picker.$emit('pick', result)
          }
        },
        {
          text: '本年',
          onClick: (picker) => {
            let now = new Date()
            let start = new Date(now.getFullYear(), 0, 1)
            let end = new Date(now.getFullYear(), 11, 31)

            let result = [start, end]
            picker.$emit('pick', result)
          }
        },
        {
          text: '上年',
          onClick: (picker) => {
            let now = new Date()
            let start = new Date(now.getFullYear() - 1, 0, 1)
            let end = new Date(now.getFullYear() - 1, 11, 31)

            let result = [start, end]
            picker.$emit('pick', result)
          }
        },
        {
          text: '近7天',
          onClick: (picker) => {
            let now = new Date()
            let start = new Date(now.getTime() - daySeconds * 6)

            let result = [start, now]
            picker.$emit('pick', result)
          }
        },
        {
          text: '近30天',
          onClick: (picker) => {
            let now = new Date()
            let start = new Date(now.getTime() - daySeconds * 29)

            let result = [start, now]
            picker.$emit('pick', result)
          }
        },
        {
          text: '近90天',
          onClick: (picker) => {
            let now = new Date()
            let start = new Date(now.getTime() - daySeconds * 89)

            let result = [start, now]
            picker.$emit('pick', result)
          }
        },
        {
          text: '近365天',
          onClick: (picker) => {
            let now = new Date()
            let start = new Date(now.getTime() - daySeconds * 364)

            let result = [start, now]
            picker.$emit('pick', result)
          }
        }
      ]

      if (this.type === 'daterange' || this.type === 'datetimerange') {
        result.shortcuts = this.$util.deepCopy(shortcuts)
      }

      return result
    },
    datePicker: {
      get() {
        return this.value
      },
      set(val = ['', '']) {
        let result = this.$util.deepCopy(val)
        this.$emit('update:value', result)
      }
    },
    startPlaceholder() {
      return this.type === 'daterange' ? '开始日期' : '开始时间'
    },
    endPlaceholder() {
      return this.type === 'daterange' ? '结束日期' : '结束时间'
    },
    isShowClose() {
      return this.clearable ? 'block' : 'none'
    },
    inputWidth() {
      let result = this.type === 'date' || this.type === 'daterange'
      return result ? '64px' : '112px'
    }
  },
  methods: {
    change() {
      this.$emit('change')
    },
    blur() {
      this.$emit('blur')
    }
  }
}
</script>

<style lang="less" scoped>
.e-date-picker {
  // padding: 3px 5px;
  /deep/ .el-range-editor--mini .el-range-separator {
    line-height: 18px;
  }
  /deep/ .el-range__close-icon {
    display: v-bind(isShowClose);
  }
  /deep/ .el-range-separator {
    line-height: 16px;
    width: auto;
    padding: 0 2px;
  }
  /deep/ .el-range-input {
    padding-top: 2px;
    color: @colorGrayDeep;
    width: v-bind(inputWidth);
  }
}
</style>
