// business 业务组件
import BDetailNav from './business/b-detail-nav'
import BDetailProcess from './business/b-detail-process'
import BDetailStep from './business/b-detail-step'
import BGoodsCategory from './business/b-goods-category/index'
import BPopupCustomer from './business/b-popup-customer'

// common 公用组件
import CBack from './common/c-back'
import CTableHeader from './common/c-table-header'

// element-ul 封装组件
import EForm from './element-ui/e-form'
import EDatePicker from './element-ui/e-date-picker'
import EUploadImage from './element-ui/e-upload-image'

const components = [
  // b-xxx
  ['b-detail-nav', BDetailNav],
  ['b-detail-process', BDetailProcess],
  ['b-detail-step', BDetailStep],
  ['b-goods-category', BGoodsCategory],
  ['b-popup-customer', BPopupCustomer],

  // c-xxx
  ['c-back', CBack],
  ['c-table-header', CTableHeader],

  // e-xxx
  ['e-form', EForm],
  ['e-date-picker', EDatePicker],
  ['e-upload-image', EUploadImage]
]

const setComponents = (app) => {
  components.forEach((item) => {
    app.use({
      install(app) {
        app.component(item[0], item[1])
      }
    })
  })
}

export default setComponents
