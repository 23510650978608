<template>
  <div class="lay-breadcrumb">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item
        v-for="(item, index) in selfList"
        :key="index"
        :to="index === selfList.length - 1 ? '' : { path: item.path }"
      >
        {{ item.name }}
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="flex flex-y-center">
      <el-link type="primary" @click.native="goNewPage">新开当前页</el-link>
      <span class="m-x-5">丨</span>
      <el-link type="primary" @click.native="goBackPage">返回</el-link>
    </div>
  </div>
</template>

<script>
import routes from '@/router/routes'
export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    navList() {
      return this.$store.getters.navList
    },
    selfList() {
      let result = [
        {
          name: '首页',
          path: '/'
        }
      ]
      let routePath = this.$route.path
      routes.forEach((itemSt) => {
        if (itemSt.children) {
          itemSt.children.forEach((itemNd) => {
            if (itemNd.children) {
              itemNd.children.forEach((itemRd) => {
                if (routePath.includes(itemRd.path)) {
                  result.push({
                    name: itemSt.meta.title,
                    path: itemSt.path
                  })
                  result.push({
                    name: itemNd.meta.title,
                    path: itemNd.path
                  })
                  result.push({
                    name: itemRd.meta.title,
                    path: itemRd.path
                  })
                }
              })
            }
          })
        }
      })
      return result
    }
  },
  methods: {
    goBackPage() {
      this.$router.go(-1)
    },
    goNewPage() {
      window.open(this.$route.fullPath, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.lay-breadcrumb {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  padding: 0 10px;
  background: @colorGrayF;
  z-index: 99;

  /deep/ .el-breadcrumb {
    font-size: 12px;
    .el-breadcrumb__separator {
      margin: 0 5px 0 2px;
    }

    .el-breadcrumb__inner a,
    .el-breadcrumb__inner.is-link {
      font-weight: normal;
      color: @colorBlack;
    }

    .el-breadcrumb__item:last-child .el-breadcrumb__inner,
    .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
      color: @colorGrayLight;
    }
  }
}
</style>
