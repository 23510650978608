<template>
  <div class="box-dialog">
    <el-dialog
      width="420px"
      :title="selfData.id ? '编辑类目' : '新增类目'"
      :visible.sync="selfShow"
      :close-on-click-modal="false"
    >
      <el-form ref="refForm" :model="selfData" :rules="rules" label-width="100px">
        <el-form-item label="所属分类:">
          <el-input v-model="categoryName" size="mini" style="width: 250px" disabled />
        </el-form-item>
        <el-form-item label="上级类目:">
          <el-input v-model="selfData.parent_name" size="mini" style="width: 250px" disabled />
        </el-form-item>
        <el-form-item label="类目层级:">
          <el-input v-model="selfData.level" size="mini" style="width: 250px" disabled />
        </el-form-item>
        <el-form-item label="类目名称:" prop="name">
          <el-input v-model="selfData.name" size="mini" style="width: 250px" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="popupCancel">取消</el-button>
        <el-button size="small" type="primary" @click="popupConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    categoryName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfData: {},
      rules: {
        name: [{ required: true, message: '请输入类目' }]
      }
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    },
    data: {
      handler(val) {
        if (JSON.stringify(this.selfData) !== JSON.stringify(val)) {
          this.selfData = this.$util.deepCopy(val)
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.selfShow = this.show
  },
  methods: {
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          this.$api({
            method: 'post',
            url: '/admin/gcvip/columnData/saveOrUpdate',
            data: this.selfData
          }).then((res) => {
            if (res.data.state === 'ok') {
              this.popupCancel()
              this.$emit('success')
              this.$message.success('保存成功')
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.box-dialog {
}
</style>
